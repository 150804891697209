<template>
  <div class="markingProgress">
    <!-- 单次考试 -->
    <template v-for="(v, i) in rateListData">
      <div v-if="showSubject(v)" :key="i" class="mark-paper-list-box">
        <div class="mark-paper-list-header">
          <span class="paper-title">
            {{ v.subjectName }}（考生人数：{{ v.examStudent }}，上传正常卡人数：
            <span class="err">{{ v.normalAnwserSheet }}</span
            >）
          </span>
          <span class="paper-check" @click="showDetails(v)">
            <template v-if="v.examPaperId == indexPaper.examPaperId">
              隐藏</template
            ><template v-else>查看</template>详细进度
            <i
              class="el-icon-arrow-down"
              :class="{ 'show-i': v.examPaperId == indexPaper.examPaperId }"
            ></i>
          </span>
        </div>

        <template v-if="!Number(v.rate)">
          <!-- 1 -->
          <el-progress
            :text-inside="true"
            :stroke-width="100"
            :percentage="0"
          ></el-progress>
        </template>
        <template v-if="0 < Number(v.rate) && Number(v.rate) <= 1">
          <el-progress
            :text-inside="true"
            :stroke-width="100"
            :percentage="Number(v.rate) * 100"
          ></el-progress>
        </template>
        <template v-if="Number(v.rate) > 1">
          <el-progress
            :text-inside="true"
            :stroke-width="100"
            :percentage="100"
          ></el-progress>
        </template>

        <div>阅卷进度：{{ v.rate | setNumber }}</div>
        <div
          v-if="v.examPaperId == indexPaper.examPaperId"
          class="set-up-rules"
        >
          <el-table
            v-if="!v.isClass"
            v-loading="boxLoading"
            :data="tableData"
            border
            style="width: 100%; margin-top: 24px"
          >
            <el-table-column
              prop="questionNum"
              label="题目"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <template
                  v-if="scope.row.isOption && scope.row.examQuestionGroup"
                  >选做题：
                </template>
                <template v-if="!scope.row.isOption">主观题：</template>
                {{ scope.row.questionNum }}
              </template>
            </el-table-column>
            <el-table-column
              prop="readCount"
              label="已阅人数"
              width="180"
              align="center"
            >
              <template slot-scope="scope">
                <!-- 选做题  非组和情况 -->
                <!-- examQuestionGroup 只会存在非组合情况 -->
                <template
                  v-if="scope.row.isOption && scope.row.examQuestionGroup"
                >
                  <!-- getNums -->
                  <template>
                    <span
                      :class="{
                        err: scope.row.workloadAll > scope.row.readCount,
                      }"
                    >
                      {{ scope.row.readCount }}
                    </span>
                    /
                    <span>{{ scope.row.workloadAll || 0 }}</span>
                  </template>
                </template>
                <!-- 非选做题 非组合情况 -->
                <template v-else>
                  <!-- {{ v.normalAnwserSheet }} -->
                  <span
                    :class="{
                      err:
                        v.normalAnwserSheet - scope.row.isBlankCount >
                        scope.row.readCount,
                    }"
                  >
                    <!-- {{ v.examStudent }} -->
                    {{ scope.row.readCount ? scope.row.readCount : 0 }}
                  </span>
                </template>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="教师阅卷情况" align="center">
              <template slot-scope="scope">
                <template
                  v-if="scope.row.isOption && !scope.row.examQuestionGroup"
                >
                  <div
                    class="task-load"
                    :class="{
                      'task-load-opt':
                        scope.row.isOption && !scope.row.examQuestionGroup,
                    }"
                  >
                    <span
                      class="task-nums-list shik-tips"
                      :class="{
                        err: scope.row.workloadAll > 0,
                      }"
                    >
                      待处理：{{ scope.row.workloadAll || 0 }}
                    </span>

                    <span
                      v-if="
                        scope.row.detailVOList &&
                        scope.row.detailVOList.length > 0
                      "
                      class="task-nums-list"
                      style="text-align: left"
                    >
                      {{ scope.row.detailVOList[0].teacherName }}
                    </span>
                    <span v-else class="err task-nums-list">未设置处理人</span>
                    <!--  :class="{ err: scope.row.isBlankCount > 0 }" -->
                    <span class="shik-tips">
                      空白题：{{ scope.row.isBlankCount }}
                    </span>
                    <!-- <template v-else>0</template> -->
                  </div>
                </template>
                <template v-else>
                  <!-- 单评 -->
                  <template v-if="scope.row.ruleScore == 1">
                    <div class="task-load">
                      <span class="task-nums-list">
                        {{ scope.row.detailVOList | getListNums(1) }}
                      </span>
                      <template v-for="(m, n) in scope.row.detailVOList">
                        <!-- {{ m.type == 1 }} -->
                        <span
                          v-if="m.type == 1"
                          :key="n"
                          class="task-nums-list"
                          :class="{
                            succ: getType(m, 1, v, scope.row),
                            err: getType(m, 2, v, scope.row),
                          }"
                        >
                          {{ m.teacherName }}
                          <span
                            >({{ (m.readCount || 0) + "/" + m.workload }})</span
                          >
                        </span>
                      </template>
                    </div>
                  </template>
                  <!-- 双评+仲裁 -->
                  <template v-if="scope.row.ruleScore == 2">
                    <div class="task-load">
                      <span class="task-load-title">多评详情：已阅/任务量</span>
                    </div>
                    <div class="task-load">
                      <span class="task-nums-list">
                        一、二评：{{ scope.row.detailVOList | getListNums(1) }}
                      </span>
                      <template v-for="(m, n) in scope.row.detailVOList">
                        <span
                          v-if="m.type == 1"
                          :key="n"
                          class="task-nums-list"
                          :class="{
                            succ: getType(m, 1, v, scope.row),
                            err: getType(m, 2, v, scope.row),
                          }"
                        >
                          {{ m.teacherName }}
                          <span
                            >({{ (m.readCount || 0) + "/" + m.workload }})</span
                          >
                        </span>
                      </template>
                    </div>
                    <div class="task-load">
                      <span
                        class="task-nums-list"
                        :class="{ err: getNumsT(scope.row.detailVOList, 4) }"
                      >
                        仲裁：{{ scope.row.detailVOList | getListNums1(4) }}
                      </span>
                      <template v-for="(m, n) in scope.row.detailVOList">
                        <span
                          v-if="m.type == 4"
                          :key="n"
                          class="task-nums-list"
                          :class="{
                            succ: getType(m, 1, v, scope.row),
                            err: getType(m, 2, v, scope.row),
                          }"
                        >
                          {{ m.teacherName }}
                          <span>({{ m.readCount || 0 }})</span>
                        </span>
                      </template>
                    </div>
                  </template>
                  <!-- 2+1+仲裁 -->
                  <template v-if="scope.row.ruleScore == 3">
                    <div class="task-load">
                      <span class="task-load-title">多评详情：已阅/任务量</span>
                    </div>
                    <div class="task-load">
                      <span class="task-nums-list">
                        一、二评：{{ scope.row.detailVOList | getListNums(1) }}
                      </span>
                      <template v-for="(m, n) in scope.row.detailVOList">
                        <span
                          v-if="m.type == 1"
                          :key="n"
                          class="task-nums-list"
                          :class="{
                            succ: getType(m, 1, v, scope.row),
                            err: getType(m, 2, v, scope.row),
                          }"
                        >
                          {{ m.teacherName }}
                          <span
                            >({{ (m.readCount || 0) + "/" + m.workload }})</span
                          >
                        </span>
                      </template>
                    </div>
                    <div class="task-load">
                      <span
                        class="task-nums-list"
                        :class="{ err: scope.row.workloadThree > 0 }"
                      >
                        三评：{{ scope.row | getType3() }}
                      </span>

                      <template v-for="(m, n) in scope.row.detailVOList">
                        <span
                          v-if="m.type == 3"
                          :key="n"
                          class="task-nums-list"
                          :class="{
                            succ: getType(m, 1, v, scope.row),
                            err: getType(m, 2, v, scope.row),
                          }"
                        >
                          {{ m.teacherName }}
                          <span>({{ m.readCount || 0 }})</span>
                        </span>
                      </template>
                    </div>
                    <div class="task-load">
                      <span
                        class="task-nums-list"
                        :class="{ err: getNumsT(scope.row.detailVOList, 4) }"
                      >
                        仲裁：{{ scope.row.detailVOList | getListNums1(4) }}
                      </span>
                      <template v-for="(m, n) in scope.row.detailVOList">
                        <span
                          v-if="m.type == 4"
                          :key="n"
                          class="task-nums-list"
                          :class="{
                            succ: getType(m, 1, v, scope.row),
                            err: getType(m, 2, v, scope.row),
                          }"
                        >
                          {{ m.teacherName }}
                          <span>({{ m.readCount || 0 }})</span>
                        </span>
                      </template>
                    </div>
                  </template>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <template v-else>
            <el-table
              v-if="titleList.length > 0"
              v-loading="boxLoading"
              :data="questionTabData"
              border
              style="width: 100%; margin-top: 24px"
            >
              <el-table-column
                prop="classNum"
                label="班级"
                width="180"
                align="center"
              >
              </el-table-column>
              {{ titleList }}

              <el-table-column
                v-for="(item, index) in titleList"
                :key="index + 'title'"
                :label="item.key + '题'"
                align="center"
              >
                <template slot-scope="scope">
                  <div
                    :class="{
                      succ: getType(scope.row[item.key], 1, v),
                      err: getType(scope.row[item.key], 2, v),
                    }"
                  >
                    {{ scope.row[item.key].readCount }}/{{
                      scope.row[item.key].workload
                    }}
                    {{ scope.row[item.key].teacherName }}
                  </div>
                </template>
              </el-table-column>

              <!-- titleList -->
            </el-table>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const readTypeList = [
  {
    value: 1,
    label: "未开始",
  },
  {
    value: 2,
    label: "阅卷中",
  },
  {
    value: 3,
    label: "已完成",
  },
];
const onLineList = [
  {
    value: 1,
    label: "批阅中",
  },
  {
    value: 2,
    label: "未在线",
  },
];
import {
  monitorRateList,
  monitorRateDetail,
  classnumDetail,
  unionRate,
  exportRate,
} from "@/core/api/exam/paperMonitor";
import { schoollistbyexamid } from "@/core/api/exam/exam";
import { getStore } from "@/core/util/store";
import { showSubject, fileDownloadByUrl } from "@/core/util/util";
import { mapGetters } from "vuex";
import {
  // schoolGroup,
  groupList,
  // delSchoolGroup,
} from "@/core/api/exam/examCorrectTask";
export default {
  name: "MarkingProgress",
  filters: {
    getListNums(list, type) {
      // console.log(list);
      // console.log(type);
      let nums1 = 0;
      let nums2 = 0;
      list.forEach((item) => {
        if (item.type == type) {
          nums1 = nums1 + (Number(item.readCount) || 0);
          nums2 = nums2 + (Number(item.workload) || 0);
        }
      });
      return `${nums1}/${nums2}`;
    },
    getType3(row) {
      let nums1 = 0;
      let { detailVOList, workloadThree } = row;
      detailVOList.forEach((item) => {
        if (item.type == 3) {
          nums1 = nums1 + (Number(item.readCount) || 0);
        }
      });
      return `${nums1}/${nums1 + workloadThree}`;
    },
    getListNums1(list, type) {
      let nums1 = 0;
      list.forEach((item) => {
        if (item.type == type) {
          nums1 = nums1 + (Number(item.readCount) || 0);
          if (type == 4) {
            nums1 = Number(item.workload);
          }
        }
      });
      return nums1;
    },
  },
  data() {
    return {
      modelSearch: {
        schoolId: "-1",
        studentExamId: "",
      },

      boxLoading: false,

      showTable: false,
      dialogVisible: false,
      btnLoading: false,
      readTypeList: readTypeList,
      onLineList: onLineList,
      rateListData: [],
      indexPaper: {},
      tableData: [],
      questionTabData: [],
      titleList: [],
      schoolList: [],
      oldSchoolList: [],
      read: [1, 2, 3],
      onLine: [1, 2],
      search: {
        schoolId: "",
      },
      pageIndex: 1,
      pageSize: 50,
      totalPage: 0,
      indexType: 0,
    };
  },

  computed: {
    ...mapGetters(["GET_TEACHER_ROLE_LIST", "GET_SCHOOL_LIST"]),
  },
  watch: {
    GET_TEACHER_ROLE_LIST: {
      deep: true,
      handler() {
        if (this.userInfo.roleId == 5) {
          this.setDetail();
        }
      },
    },
    GET_SCHOOL_LIST: {
      deep: true,
      handler() {
        this.schoolList = this.GET_SCHOOL_LIST;
        this.oldSchoolList = JSON.parse(JSON.stringify(this.GET_SCHOOL_LIST));
        if (this.schoolList.length > 0) {
          this.schoolId = "-1";
        }
      },
    },
  },
  created() {
    this.userInfo = getStore({ name: "userInfo" });
    this.monitorRateList();
    if (this.$route.query.getType == 3) {
      this.groupList();
    } else {
      // this.getSchoolList();
    }
    this.schoolList = this.GET_SCHOOL_LIST;
    this.oldSchoolList = JSON.parse(JSON.stringify(this.GET_SCHOOL_LIST));
    if (this.schoolList.length > 0) {
      this.schoolId = "-1";
    }
  },
  methods: {
    getNumsT(list, type) {
      let nums1 = 0;
      list.forEach((item) => {
        if (item.type == type) {
          nums1 = nums1 + (Number(item.readCount) || 0);
          if (type == 4) {
            nums1 = Number(item.workload);
          }
        }
      });
      return nums1 > 0 ? true : false;
    },
    getNums(list, type) {
      // console.log(list);
      // console.log(type);
      let nums1 = 0;

      list.forEach((item) => {
        if (type == 1) {
          nums1 = nums1 + (Number(item.readCount) || 0);
        } else {
          nums1 = nums1 + (Number(item.workload) || 0);
        }
      });
      return `${nums1}`;
    },

    exportRate() {
      let data = {
        examPaperId: this.indexPaper.examPaperId,
      };
      this.btnLoading = true;
      exportRate(data)
        .then((res) => {
          this.btnLoading = false;
          // console.log(res);
          fileDownloadByUrl(res.data.data.url, res.data.data.name);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    groupList() {
      let data = {
        examId: this.$route.query.examId,
      };
      this.loading = true;
      groupList(data)
        .then((res) => {
          // console.log(res);
          this.loading = false;
          res.data.data.map((index) => {
            index.schoolIds = index.id;
            index.schoolName = index.name;
          });
          // this.oldSchoolGroupList = JSON.parse(JSON.stringify(res.data.data));
          this.schoolList = res.data.data;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 是否显示试题
    getShowQ(item) {
      let workload =
        (item.workload1 || 0) + (item.workload2 || 0) + (item.workload3 || 0);
      let readCount =
        (item.readCount1 || 0) +
        (item.readCount2 || 0) +
        (item.readCount3 || 0);
      let type = true;
      if (this.read.indexOf(1) == -1) {
        if (readCount == 0) {
          type = false;
        }
      }
      if (this.read.indexOf(2) == -1) {
        if (workload > readCount && readCount > 0) {
          type = false;
        }
      }
      if (this.read.indexOf(3) == -1) {
        if (workload == readCount && readCount > 0) {
          type = false;
        }
      }
      return type;
      // if(workload ==readCount){

      // }
      // if(item.workload1+ )
    },
    // 是否显示列表
    getLine(item) {
      let type = true;
      if (this.onLine.indexOf(1) == -1) {
        if (item.markInfo) {
          type = false;
        }
      }
      if (this.onLine.indexOf(2) == -1) {
        if (!item.markInfo) {
          type = false;
        }
      }
      return type;
    },
    checkBtn(index, key) {
      if (this[key].indexOf(index) == -1) {
        this[key].push(index);
      } else {
        this[key] = this[key].filter((item) => item != index);
      }
    },
    getNum(item) {
      let num =
        (((item.readCount1 || 0) +
          (item.readCount2 || 0) +
          (item.readCount3 || 0)) *
          100) /
        ((item.workload1 || 0) +
          (item.workload2 || 0) +
          (item.workload3 || 0) || 1);
      if (num > 0) {
        num = Number(num.toFixed(2));
      }
      // console.log(num);

      return num;
    },
    async getSchoolList() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });
      this.schoolList = res.data.data;
    },
    getSchoolRate() {
      this.unionRate(this.indexPaper);
    },
    showUnionRate(item) {
      if (this.indexPaper.examPaperId == item.examPaperId) {
        this.indexPaper = {};
        return;
      }
      this.unionRate(item);
    },
    unionRate(item) {
      let data = {
        examPaperId: item.examPaperId,
        schoolId: this.search.schoolId,
        type: this.$route.query.getType,
      };
      // data.examPaperId = 35070;
      // data.schoolId = 20212;
      unionRate(data).then((res) => {
        res.data.data.examPaperVOList.map((index) => {
          index.showList = false;
        });
        this.indexPaper = res.data.data;
        // this.indexPaper.examPaperVOList
        // console.log(res.data);
      });
    },
    showSubject(v) {
      return showSubject(v, 9);
    },
    // getTypeFn(){
    //   type0:function(){

    //   }
    // },
    getType(item, type, v, row) {
      // item 当前教师 ，type：类型 1是绿色  2是红色 v：科目列表 row ：试题列表
      // console.log(v);
      let retType = false;
      let workload = Number(item.workload || 0);
      // 须阅量任务总数
      let workloadAll = 0;
      if (row) {
        workloadAll = Number(row.workloadAll || 0);
      }
      if (row && row.ruleScore != 1) {
        // console.log(row);
        workloadAll = workloadAll * 2;
      }
      let rowWorkload = 0;
      // 已阅量
      let readCount = Number(item.readCount);
      let readCountAll = 0;
      if (row) {
        readCountAll = Number(row.readCount);
      }

      if (type == 1) {
        if (workload && workload <= readCount) {
          retType = true;
        }
        if (workloadAll <= readCountAll && readCountAll) {
          retType = true;
        }
        if (!v.isClass) {
          let rowReadCount = 0;
          row.detailVOList.map((index) => {
            if (index.type == item.type) {
              rowReadCount = Number(index.readCount) + rowReadCount;
            }
          });
          if (rowReadCount == workloadAll) {
            retType = true;
          }
        }
        // 最后一级判断  如果是选做题 没有总任务量  就显示为灰色
        if (row && row.isOption == 1 && readCount == 0 && workloadAll == 0) {
          retType = true;
        }
      } else {
        if (workload > readCount) {
          retType = true;
        }
        // 添加判断  班级批阅情况下
        if (v.isClass == 1) {
          // console.log(item);
          let { questionNum } = item;
          let quantity = 0;
          this.tableData.map((iKey) => {
            if (questionNum == iKey.questionNum) {
              let readCountA = iKey.readCount ? Number(iKey.readCount) : 0;
              quantity = readCountA + quantity;
            }
          });
          // console.log(quantity);
          if (quantity >= v.normalAnwserSheet) {
            retType = false;
          }
        } else {
          let rowReadCount = 0;
          row.detailVOList.map((index) => {
            if (index.type == item.type) {
              rowReadCount = Number(index.readCount) + rowReadCount;
            }
            if (index.type == 3) {
              rowWorkload = rowWorkload + Number(index.readCount);
            }
          });
          if (rowReadCount == workloadAll) {
            retType = false;
          }
          if (item.type == 3) {
            rowWorkload = rowWorkload + Number(row.workloadThree);
            if (rowReadCount >= rowWorkload) {
              retType = false;
            }
            if (row.workloadThree == 0) {
              retType = false;
            }
          }
          // console.log(JSON.parse(JSON.stringify(row)));
        }

        if (workloadAll <= readCountAll && readCountAll) {
          retType = false;
        }

        // 最后一级判断  如果是选做题 没有总任务量  就显示为灰色
        if (row && row.isOption == 1 && readCount == 0 && workloadAll == 0) {
          retType = false;
        }
      }
      if (item.type == 4) {
        retType = false;
      }
      // console.log(item);
      return retType;
    },
    showDetails(v) {
      if (this.indexPaper.examPaperId == v.examPaperId) {
        this.indexPaper = {};
        return;
      }
      this.indexPaper = v;
      let query = JSON.parse(JSON.stringify(this.$route.query));
      query.examPaperId = v.examPaperId;
      this.$router.push({
        query: query,
      });
      if (v.isClass) {
        this.classnumDetail();
      } else {
        this.monitorRateDetail();
      }
    },
    // 根据权限获取列表
    setDetail() {
      if (!this.GET_TEACHER_ROLE_LIST || !this.oldTableData) {
        return;
      }

      let roleList = this.GET_TEACHER_ROLE_LIST.filter(
        (item) => item.subjectType == 8
      );
      // console.log(roleList);
      this.tableData = [];
      if (roleList.length > 0) {
        roleList.forEach((item) => {
          this.rateListData.forEach((index) => {
            if (item.subjectId == index.subjectId) {
              this.tableData = this.oldTableData;
            }
          });
        });
      }
    },
    // 获取单科阅卷详情
    monitorRateDetail() {
      let data = {
        examPaperId: this.indexPaper.examPaperId,
      };
      this.boxLoading = true;
      monitorRateDetail(data)
        .then((res) => {
          this.oldTableData = res.data.data;
          //老师的情况根据权限显示
          if (this.userInfo.roleId == 5) {
            this.setDetail();
          } else {
            this.tableData = this.oldTableData;
          }
          this.boxLoading = false;
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    // 获取单科阅卷详情 按班级批阅
    classnumDetail() {
      let data = {
        examPaperId: this.indexPaper.examPaperId,
      };
      this.boxLoading = true;
      classnumDetail(data)
        .then((res) => {
          let tableData = res.data.data;

          // 循环的数据
          this.titleList = [];
          // 班级列表
          let classArr = [];
          // 试题列表
          let questionArr = [];
          tableData.map((item) => {
            classArr.push(item.classNum);
            questionArr.push(item.questionNum);
          });
          classArr = [...new Set(classArr)];
          questionArr = [...new Set(questionArr)];
          this.questionTabData = [];
          let titleData = {};
          questionArr.forEach((item) => {
            titleData[item] = "";
            // titleData.key = item;
            this.titleList.push({
              key: item,
            });
          });
          classArr.forEach((item) => {
            let data = {
              classNum: item,
            };
            Object.assign(data, titleData);
            this.questionTabData.push(data);
          });
          // console.log(this.titleList);
          this.questionTabData.map((item) => {
            tableData.map((index) => {
              this.titleList.map((i) => {
                if (
                  item.classNum == index.classNum &&
                  i.key == index.questionNum
                ) {
                  let questionData = {
                    workload: index.workload,
                  };
                  questionData.readCount = index.readCount || 0;
                  questionData.questionNum = index.questionNum;
                  questionData.teacherName = index.teacherName || "";
                  // questionData[i.key] = index.readCount || 0;
                  item[i.key] = questionData;
                }
              });
            });
          });
          this.tableData = tableData;
          this.boxLoading = false;
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    monitorRateList() {
      let data = {
        examId: this.$route.query.examId,
      };
      monitorRateList(data).then((res) => {
        // console.log(res);
        this.rateListData = res.data.data;
        this.rateListData.map((item) => {
          if (this.$route.query.examPaperId == item.examPaperId) {
            this.showDetails(item);
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.markingProgress {
  .student-box {
    .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
    .model-search-box {
      margin-bottom: 24px;
      .el-input,
      .el-select {
        width: 180px;
      }
      .el-select {
        margin-right: 24px;
      }
      .el-input {
        margin-right: 24px;
      }
    }
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #ededef;
    }
  }

  .succ {
    color: #82848a !important;
  }
  .err {
    color: #f56c6c !important;
  }
  .mark-paper-list-box {
    padding: 24px;
    border-bottom: 1px dashed #e4e4e4;

    ::v-deep .set-up-rules {
      .el-table__header {
        th {
          padding: 18px 0;
        }
      }
      .cell {
        padding: 0;
      }
    }
    .task-load {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
      border-top: 1px solid #ebeef5;
      padding: 6.5px 10px;
      .task-nums-list {
        color: #161817;
        margin-right: 24px;
      }
      &:first-child {
        border-top: none;
      }
      .task-load-title {
        color: #d8d8d8;
      }
    }
    .task-load-opt {
      flex-wrap: nowrap;
      .shik-tips {
        flex-shrink: 0;
      }
    }
    .el-progress {
      height: 12px;
      margin: 24px 0;
      ::v-deep .el-progress-bar__outer {
        height: 12px !important;
        .el-progress-bar__innerText {
          display: none;
        }
      }
    }
    .teacher-box {
      .teacher-list-box {
        display: flex;
        flex-wrap: wrap;
        .teacher-list {
          width: 280px;
          margin-right: 24px;
          margin-bottom: 24px;
          border-radius: 4px;
          border: 1px solid #dee1e3;
          overflow: hidden;
        }
        .on-line {
          .teacher-box-title {
            background-color: #fff7f7;
            span {
              &:first-child {
                cursor: pointer;
              }
            }
          }
          .style-color {
            color: #d92020;
          }
        }
        .style-color {
          color: $primary-color;
        }
        .teacher-cnt {
          padding: 18px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          // .tItem-num {
          //   width: 50%;
          // }
          .el-progress {
            margin: 18px 0 0 0;
            width: 100%;
            display: flex;
            justify-content: space-between;

            ::v-deep .el-progress__text {
              font-size: 14px !important;
            }
          }
        }
        .teacher-box-title {
          display: flex;
          justify-content: space-between;
          padding: 16px;
          background: #f7f9fb;
          font-size: 12px;
          line-height: 12px;
          span {
            span {
              color: #606266;
            }
          }
        }
      }
      .rate-tips {
        border-radius: 3px;
        padding: 7px 0;
        text-align: center;
        background: #f2faff;
        color: #2474ed;
        border: 1px solid #9dc4ff;
        width: 76px;
        font-size: 14px;
        margin-bottom: 24px;
      }
    }

    .question-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 24px;
      background: #f5f7fa;
      border: 1px solid #dee1e3;
      margin: 0 0 24px;
      cursor: pointer;
      .el-icon-arrow-down {
        transition: 0.2s;
      }
      .show-i {
        transform: rotate(180deg);
        transition: 0.2s;
      }
      .question-left {
        display: flex;
        align-items: center;
        .title {
          display: inline-block;
          min-width: 100px;
          color: #101011;
          font-weight: bold;
        }
        .num-item {
          border-radius: 3px;
          padding: 7px 10px;
          background: #f2faff;
          color: #2474ed;
          border: 1px solid #9dc4ff;
          margin-left: 12px;
        }
      }
      .el-progress {
        width: 300px;
        margin: 0 12px;
        display: flex;
        ::v-deep .el-progress__text {
          font-size: 14px !important;
        }
      }
    }
    .search-box {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
      }
      .el-button {
        float: right;
      }
      .check-item-box {
        display: flex;
        align-items: center;
        div {
          padding: 9px 8px;
          margin-right: 18px;
          border-radius: 4px;
          background: #c3c7cc;
          color: #ffffff;
          cursor: pointer;
        }
        .on {
          &:nth-child(1) {
            background: #fc8586;
          }
          &:nth-child(2) {
            background: #2474ed;
          }
          &:nth-child(3) {
            background: #04c391;
          }
        }
      }
      .el-select {
        margin-right: 32px;
      }
    }
    .rate-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .el-progress {
        width: calc(100% - 150px);
        // margin: 8px 0;
      }
    }
    .mark-paper-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .paper-check {
        color: $primary-color;
        cursor: pointer;
        .el-icon-arrow-down {
          transition: 0.2s;
        }
        .show-i {
          transform: rotate(180deg);
          transition: 0.2s;
        }
      }
      .paper-title {
        position: relative;
        .err {
          color: #ff2b00;
        }
        &::after {
          content: "";
          width: 4px;
          height: 12px;
          background: $primary-color;
          position: absolute;
          left: -8px;
          top: 4px;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
